.myModalContent {
    background-color: #fff;
    margin: 100px auto;
    padding: 20px;
    width: 80%;
    max-width: 1400px;
}

span {
    cursor: pointer;
}

.optionRed {
    color: red;
}

.solid-line {
border-bottom: 2px solid red;
padding-bottom: 5px;
}

.video {
    width: 100%;
}

.greyBackground {
    background-color: lightgray;
}

.whiteBackground {
background-color: white;
}